.leftbox {
  float:left;
  width:10%;
  height:50px;
}

.middlebox{
  float:left;
  width:80%;
  height:50px;
}

.rightbox{
  float:right;
  width:10%;
  height:50px;
}

.value, .label {
  padding: 20px;
}
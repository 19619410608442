.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height:100vh;
}

.loading-text {
    font-family: 'Krona One', sans-serif;
    font-size: medium;
}
:root {
	--ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
}

.header-logo {
  font-family: 'Krona One', sans-serif;
  margin: 50px;
}

.link-hover {
	display: inline-block;
	position: relative;
	cursor: pointer;
	text-decoration: none;
	color: white;


	@media (hover: none) {
		text-decoration: underline;
	}

	@media (hover: hover) {
		&::after {
			pointer-events: none;
			background-color: currentColor;
			bottom: 0;
			content: "";
			height: 2px;
			left: 0;
			position: absolute;
			transform: scaleX(0);
			transform-origin: right;
			transition: transform 400ms var(--ease-out-expo);
			width: 100%;
		}

		&:hover::after {
			transform: scaleX(1);
			transform-origin: left;
		}
	}

	&:visited {
		color: white;
	}
}

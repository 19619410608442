h2{
  font-size:14px;
  text-transform:uppercase;
  letter-spacing:3px;
  margin-top: 70px;
  margin-bottom:20px;
}


/* Wrapper Is not Needed| Just for demo purpose.  */
.wrapper {
  margin: 150px auto;
  text-align: center;
  width: 100%;
  position: relative;
}

.btn6{
  padding: 15px 100px;
  margin:10px 4px;
  color: black;
  font-family: sans-serif;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  text-decoration: none;
  display:inline-block;
  
}

/*=======Button 6============= */
.btn6{
  border:1px solid transparent; 
   -webkit-transition: all 0.4s cubic-bezier(.5, .24, 0, 1);
  transition: all 0.4s cubic-bezier(.5, .24, 0, 1);
}

.btn6::before {
  content: '';
  position: absolute;
  left: 0px;
  bottom:0px;
  z-index:-1;
  width: 0%;
  height:1px;
  background: black;
  box-shadow: inset 0px 0px 0px black;
  display: block;
  -webkit-transition: all 0.4s cubic-bezier(.5, .24, 0, 1);
  transition: all 0.4s cubic-bezier(.5, .24, 0, 1)
}

.btn6:hover::before {
  width:100%;
}

.btn6::after {
  content: '';
  position: absolute;
  right: 0px;
  top:0px;
  z-index:-1;
  width: 0%;
  height:1px;
  background: black;
  -webkit-transition: all 0.4s cubic-bezier(.5, .24, 0, 1);
  transition: all 0.4s cubic-bezier(.5, .24, 0, 1)
}
.btn6:hover::after {
  width:100%;
}
.btn6:hover{
  border-left:1px solid black;
  border-right:1px solid black;
}

#file {
   display: none;
}


.image_container {
  width: 40%;
  height: 40vh;
  padding: 20px;
  margin: 15px;
  display: flex;
  justify-content: center;
}

.selected-image {
  max-width: 100%;
  object-fit: contain;
}

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 45vh;
}

.segment-slider {
  margin-top: 5vmin;
}

.color_picker {
  width: 100%;
  height: 20px;
}

.color-picker-outer {
  /* padding: 1vmin; */
}

.row {
  /* margin: 5px; */
}

.row:after {
content: "";
display: table;
clear: both;
}

.center {
display: block;
margin-left: auto;
margin-right: auto;
}

.row_resizeable {
  display: flex;
  height: 1vh;
  width: 80%;
}

.column {
  float: left;
  padding: .1px;
  width: 80%;
}

.left{
  float:left;
  width:10%;
}

.right {
  float:right;
  width:10%;
}

.middle {
  float:left;
  width:80%;
}